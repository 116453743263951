// eslint-disable-next-line no-shadow
export enum EPropertyStatus {
  new,
  updated,
  loaded
}

export interface IPageProperty {
  id?: number,
  pageId?: number,
  name: string,
  required: boolean,
  type: string,
  status: EPropertyStatus,
  sort: number,
  postType?: number,
  customerType?: number
}

export type DeletePropertyProps = {
  workspaceId: number,
  id: number | null,
}

export interface IPropertyRequestData {
  id: number,
  value: any
}

export interface IPropertyResponse {
  id: number,
  name: string,
  type: string,
  required: boolean
}

export interface IPageType {
  id?: number,
  name: string
}