import React from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import ScrollReset from 'components/ScrollReset'
import Toast from 'components/Toast'
import routes, { renderRoutes } from './routes'

export const history = createBrowserHistory()

const App = (): JSX.Element => {
  return (
    <Router history={history}>
      <ScrollReset />
      <Toast />
      {renderRoutes(routes)}
    </Router>
  );
}

export default App
