import React, {
  createContext,
  useEffect
} from 'react'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { getAuth, getGlobalLoader } from 'selectors'
import { getAuthMeRequest, getAuthMeError } from 'actions/authActions'
import { resetGlobalLoader } from 'actions/globalActions'
import FullscreenLoader from 'components/FullscreenLoader'

const setSession = (accessToken: string) => {
  if (accessToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }
};

const AuthContext: any = createContext({
  isAuth: null
});

type Props = {
  children: React.ReactNode
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const auth = useSelector(getAuth)
  const dispatch = useDispatch()
  const isGlobalLoading = useSelector(getGlobalLoader)

  useEffect(() => {
    try {
      const accessToken = localStorage.getItem('accessToken');

      if (accessToken) {
        setSession(accessToken);

        // PROFILE ME ROUTE
        dispatch(getAuthMeRequest())
      } else {
        sessionStorage.removeItem('activeWorkspace')
        dispatch(getAuthMeError([{ message: '' }]))
        dispatch(resetGlobalLoader(false))
      }
    } catch (err) {
      // eslint-disable-next-line
      localStorage.clear()
    }

  }, []);

  if (!auth.user && auth.isLoading) {
    return <FullscreenLoader />
  }

  return (
    <>
      {isGlobalLoading && <FullscreenLoader />}
      <AuthContext.Provider
        value={{ isAuth: localStorage.getItem('accessToken') }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};

export default AuthContext;
