import { all } from "redux-saga/effects"
import authSaga from './authSaga'
import settingsSaga from "./settingsSaga"
import usersSaga from './usersSaga'
import workspaceSaga from './workspaceSaga'
import postsSaga from './postsSaga'
import feedsSaga from './feedsSaga'

function* saga(): any {
    yield all([
        authSaga(),
        workspaceSaga(),
        usersSaga(),
        settingsSaga(),
        postsSaga(),
        feedsSaga()
    ])
}

export default saga;