import { makeStyles } from '@material-ui/core'
import React from 'react'
import { logoutRequest } from 'actions/authActions'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

const useStyle = makeStyles({
  wrapper: {
    borderRadius: '6px',
    background: '#FFF',
    width: 256,
    position: 'fixed',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.07)',
    color: '#1C1C2E',
    top: '60px',
    marginTop: '4px',
    zIndex: 1,
  },
  row: {
    padding: '6px 12px',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)',
    }
  },
  section: {
    padding: '6px 0',
    borderBottom: '1px solid rgba(0, 0, 0, 0.04)',
    '&:last-child': {
      borderBottom: 'none',
    }
  },
})

export type Props = {
  handleClose: () => void
}

const UserDropdown = ({ handleClose }: Props): JSX.Element => {
  const classes = useStyle()
  const dispatch = useDispatch()

  const history = useHistory()

  const handleLogout = () => {
    dispatch(logoutRequest())
  }

  return (
    <section className={classes.wrapper}>
      <div className={classes.section}>
        <div className={classes.row}>
          <p>View profile</p>
        </div>
        <div
          className={classes.row}
          onClick={() => {
            handleClose()
            history.push('/settings/user')
          }}
        >
          <p>Settings</p>
        </div>
      </div>
      <div className={classes.section}>
        <div
          className={classes.row}
          onClick={() => {
            handleClose()
            handleLogout()
          }}
        >
          <p>Logout</p>
        </div>
      </div>
    </section>
  )
}

export default UserDropdown