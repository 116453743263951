import { useContext } from 'react'
import AuthContext from '../contexts/AuthContext'

type Context = {
  isAuth: string | null
}

const useAuth = (): Context => useContext(AuthContext);

export default useAuth;
