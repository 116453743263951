import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      height: '100%',
      width: '100%',
      background: '#FCFCFC',
      fontFamily: 'Helvetica Neue LT GEO',
      fontWeight: 500,
      fontStyle: 'normal',
      fontSize: 14,
      color: '#1C1C2E',
      overflow: 'hidden'
    },
    '#root': {
      height: '100%',
      width: '100%'
    },
    '.MuiPopover-root .MuiPaper-root': {
      boxShadow: '0px 8px 32px rgba(0, 0, 0, 0.07) !important',
      borderRadius: 6
    }
  }
}));

const GlobalStyles = (): null => {
  useStyles();

  return null;
};

export default GlobalStyles;
