import { combineReducers } from "redux"
import userPropertiesReducer from "./userPropertiesReducer"
import postPropertiesReducer from './postPropertiesReducer'
import userTypesReducer from './userTypesReducer'
import postTypesReducer from './postTypesReducer'

const settingsReducer = combineReducers({
  userProperties: userPropertiesReducer,
  postProperties: postPropertiesReducer,
  customerTypes: userTypesReducer,
  postTypes: postTypesReducer
})

export default settingsReducer