import { IAction, ITypesState } from "types/redux"
import * as actions from 'actions/settingsActions'

const initialState = {
  items: [],
  isLoading: false,
  error: null
}

const userTypesReducer = (state: ITypesState = initialState, action: IAction): Object => {
  switch (action.type) {
    case actions.GET_USER_TYPES_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case actions.GET_USER_TYPES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.data
      }
    case actions.GET_USER_TYPES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actions.CUSTOMER_TYPE_TITLE_CHANGE:
      const { id, name } = action.data
      const newTypes = [...state.items]
      const index = newTypes.findIndex(el => el.id === id)
      newTypes[index].name = name

      return {
        ...state,
        items: newTypes
      }
    case actions.DELETE_CUSTOMER_TYPE_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case actions.DELETE_CUSTOMER_TYPE_SUCCESS:
      const newCustomerTypes = state.items.filter(el => el.id !== action.data)
      return {
        ...state,
        isLoading: false,
        items: newCustomerTypes
      }
    case actions.DELETE_CUSTOMER_TYPE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actions.CREATE_CUSTOMER_TYPE_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case actions.CREATE_CUSTOMER_TYPE_SUCCESS:
      const newData = [...state.items.filter(el => el.id !== 0)]

      return {
        ...state,
        isLoading: false,
        items: [...newData, action.data]
      }
    case actions.CREATE_CUSTOMER_TYPE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actions.UPDATE_CUSTOMER_TYPE_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case actions.UPDATE_CUSTOMER_TYPE_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case actions.UPDATE_CUSTOMER_TYPE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default userTypesReducer