import {
  ICreatePostRequest,
  ICreatePostResponse,
  IPostRequestData,
  IPostsResponse,
  IUpdatePostStatusRequest
} from 'types/posts'
import { IAction, IError } from 'types/redux'

export const GET_POSTS_REQUEST = "GET_POSTS_REQUEST"
export const GET_POSTS_SUCCESS = "GET_POSTS_SUCCESS"
export const GET_POSTS_ERROR = "GET_POSTS_ERROR"

export const GET_POST_REQUEST = "GET_POST_REQUEST"
export const GET_POST_SUCCESS = "GET_POST_SUCCESS"
export const GET_POST_ERROR = "GET_POST_ERROR"

export const DELETE_POST_REQUEST = "DELETE_POST_REQUEST"
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS"
export const DELETE_POST_ERROR = "DELETE_POST_ERROR"

export const UPDATE_POST_REQUEST = "UPDATE_POST_REQUEST"
export const UPDATE_POST_SUCCESS = "UPDATE_POST_SUCCESS"
export const UPDATE_POST_ERROR = "UPDATE_POST_ERROR"

export const UPDATE_POST_STATUS_REQUEST = "UPDATE_POST_STATUS_REQUEST"
export const UPDATE_POST_STATUS_SUCCESS = "UPDATE_POST_STATUS_SUCCESS"
export const UPDATE_POST_STATUS_ERROR = "UPDATE_POST_STATUS_ERROR"

export const CREATE_POST_REQUEST = "CREATE_POST_REQUEST"
export const CREATE_POST_SUCCESS = "CREATE_POST_SUCCESS"
export const CREATE_POST_ERROR = "CREATE_POST_ERROR"

export const getPostsRequest = (data: number, token?: string): IAction => ({
  type: GET_POSTS_REQUEST,
  data,
  token
})

export const getPostsSuccess = (data: IPostsResponse): IAction => ({
  type: GET_POSTS_SUCCESS,
  data
})

export const getPostsError = (error: IError): IAction => ({
  type: GET_POSTS_ERROR,
  error
})

export const getPostRequest = (data: IPostRequestData): IAction => ({
  type: GET_POST_REQUEST,
  data
})

export const getPostSuccess = (data: unknown): IAction => ({ // change type IPostsResponse!
  type: GET_POST_SUCCESS,
  data
})

export const getPostError = (error: IError): IAction => ({
  type: GET_POST_ERROR,
  error
})

export const deletePostRequest = (data: number): IAction => ({
  type: DELETE_POST_REQUEST,
  data
})

export const deletePostSuccess = (data: number): IAction => ({
  type: DELETE_POST_SUCCESS,
  data
})

export const deletePostError = (error: IError): IAction => ({
  type: DELETE_POST_ERROR,
  error
})

export const updatePostRequest = (data: object): IAction => ({
  type: UPDATE_POST_REQUEST,
  data
})

export const updatePostSuccess = (data: ICreatePostResponse): IAction => ({
  type: UPDATE_POST_SUCCESS,
  data
})

export const updatePostError = (error: IError): IAction => ({
  type: UPDATE_POST_ERROR,
  error
})

export const updatePostStatusRequest = (data: IUpdatePostStatusRequest): IAction => ({
  type: UPDATE_POST_STATUS_REQUEST,
  data
})

export const updatePostStatusSuccess = (data: unknown): IAction => ({
  type: UPDATE_POST_STATUS_SUCCESS,
  data
})

export const updatePostStatusError = (error: IError): IAction => ({
  type: UPDATE_POST_STATUS_ERROR,
  error
})

export const createPostRequest = (data: ICreatePostRequest): IAction => ({
  type: CREATE_POST_REQUEST,
  data
})

export const createPostSuccess = (data: ICreatePostResponse): IAction => ({
  type: CREATE_POST_SUCCESS,
  data
})

export const createPostError = (error: IError): IAction => ({
  type: CREATE_POST_ERROR,
  error
})