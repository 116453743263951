import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Snackbar, makeStyles } from '@material-ui/core'
import { getToast } from 'selectors'
import { hideToast } from 'actions/globalActions'

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    background: '#28283D',
    color: '#ffffff',
    boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.08)',
    borderRadius: 6,
    width: 394,
    height: 112
  },
  iconWrapper: {
    padding: '32px 16px 32px 24px'
  },
  icon: {
    background: 'rgba(255, 255, 255, 0.06)',
    width: 48,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    '& img': {
      width: 18,
      height: 18
    }
  },
  infoWrapper: {
    padding: '24px 16px 24px 0',
    color: '#8C90A3'
  },
  title: {
    fontWeight: 700,
    color: '#ffffff'
  }
})

const Toast = (): JSX.Element => {
  const {
    show,
    // data
  } = useSelector(getToast)
  const dispatch = useDispatch()
  const classes = useStyles()

  const handleClose = () => {
    dispatch(hideToast())
  }

  return (
    <div>
      <Snackbar
        open={show}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <div className={classes.wrapper}>
          <div className={classes.iconWrapper}>
            <div className={classes.icon}>
              <img src='img/feeds-icon.svg' alt='Feeds icon' />
            </div>
          </div>
          <div className={classes.infoWrapper}>
            <p className={classes.title}>Congrats!</p>
            <p>You just created your first feed. Now its time to integrate</p>
          </div>
        </div>
      </Snackbar>
    </div>
  )
}

export default Toast