import { IFeed, IFeedResponse, IGetFeedsResponse } from "types/feeds"
import { IAction, IError } from "types/redux"

export const GET_FEEDS_REQUEST = "GET_FEEDS_REQUEST"
export const GET_FEEDS_SUCCESS = "GET_FEEDS_SUCCESS"
export const GET_FEEDS_ERROR = "GET_FEEDS_ERROR"

export const GET_FEED_REQUEST = "GET_FEED_REQUEST"
export const GET_FEED_SUCCESS = "GET_FEED_SUCCESS"
export const GET_FEED_ERROR = "GET_FEED_ERROR"

export const UPDATE_FEED_REQUEST = "UPDATE_FEED_REQUEST"
export const UPDATE_FEED_SUCCESS = "UPDATE_FEED_SUCCESS"
export const UPDATE_FEED_ERROR = "UPDATE_FEED_ERROR"

export const REMOVE_FEED_REQUEST = "REMOVE_FEED_REQUEST"
export const REMOVE_FEED_SUCCESS = "REMOVE_FEED_SUCCESS"
export const REMOVE_FEED_ERROR = "REMOVE_FEED_ERROR"

export const CREATE_FEED_REQUEST = "CREATE_FEED_REQUEST"
export const CREATE_FEED_SUCCESS = "CREATE_FEED_SUCCESS"
export const CREATE_FEED_ERROR = "CREATE_FEED_ERROR"

type GetFeedsProps = {
  workspaceId: number,
  pageToken?: string
}
export const getFeedsRequest = ({ workspaceId, pageToken }: GetFeedsProps): IAction => ({
  type: GET_FEEDS_REQUEST,
  data: {
    workspaceId,
    pageToken
  }
})

export const getFeedsSuccess = (data: IGetFeedsResponse): IAction => ({
  type: GET_FEEDS_SUCCESS,
  data
})

export const getFeedsError = (error: IError): IAction => ({
  type: GET_FEEDS_ERROR,
  error
})

type GetFeedProps = {
  workspaceId: number,
  feedId: number,
}
export const getFeedRequest = ({ workspaceId, feedId }: GetFeedProps): IAction => ({
  type: GET_FEED_REQUEST,
  data: {
    workspaceId,
    feedId
  }
})

export const getFeedSuccess = (data: IFeedResponse): IAction => ({
  type: GET_FEED_SUCCESS,
  data
})

export const getFeedError = (error: IError): IAction => ({
  type: GET_FEED_ERROR,
  error
})

export type UpdateFeedProps = {
  workspaceId: number,
  feedId: number,
  body: IFeed
}

export const updateFeedRequest = ({ workspaceId, feedId, body }: UpdateFeedProps): IAction => ({
  type: UPDATE_FEED_REQUEST,
  data: {
    workspaceId,
    feedId,
    body
  }
})

export const updateFeedSuccess = (data: IFeedResponse): IAction => ({
  type: UPDATE_FEED_SUCCESS,
  data
})

export const updateFeedError = (error: IError): IAction => ({
  type: UPDATE_FEED_ERROR,
  error
})

type CreateFeedProps = {
  workspaceId: number,
  body: IFeed,
  closeScreen: () => void
}
export const createFeedRequest = ({ workspaceId, body, closeScreen }: CreateFeedProps): IAction => ({
  type: CREATE_FEED_REQUEST,
  data: {
    workspaceId,
    body,
    closeScreen
  },
})

export const createFeedSuccess = (data: IFeedResponse): IAction => ({
  type: CREATE_FEED_SUCCESS,
  data
})

export const createFeedError = (error: IError): IAction => ({
  type: CREATE_FEED_ERROR,
  error
})

export const removeFeedRequest = (data: number): IAction => ({
  type: REMOVE_FEED_REQUEST,
  data
})

export const removeFeedSuccess = (data: number): IAction => ({
  type: REMOVE_FEED_SUCCESS,
  data
})

export const removeFeedError = (error: IError): IAction => ({
  type: REMOVE_FEED_ERROR,
  error
})