import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ClickAwayListener, makeStyles } from '@material-ui/core'
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import HomeRoundedIcon from '@material-ui/icons/HomeRounded'
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded'
import clsx from 'clsx'
import { getCurrentWorkspace, getUser, getWorkspace, getMenuOpen } from 'selectors'
import { setActiveId } from 'actions/workspaceActions'
import { IWorkspaceState } from 'types/redux'
import ActionLinks, { ILink } from './ActionLinks'
import Menu, { IMenuSection } from './Menu'
import SettingsMenu from './SettingsMenu'
import FeedUsage from './FeedUsage'
import WorkspaceDropdown from './WorkspaceDropdown'
import UserDropdown from './UserDropdown'

const useStyle = makeStyles(theme => ({
  wrapper: {
    backgroundColor: '#1C1C2E',
    color: '#FFF',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      height: '100vh',
      paddingTop: '52px',
      transform: 'translateX(-100%)',
      zIndex: 1,
      transition: '0.5s',
      '&.active': {
        transform: 'translateX(0)',
        transition: '0.5s',
      }
    }
  },
  info: {
    padding: '24px 16px',
    display: 'flex',
    flexDirection: 'column',
    color: '#8C90A3',
    width: '288px',
    overflow: 'hidden'
  },
  heading: {
    color: '#FFF',
    fontWeight: 'bold',
    marginLeft: '8px',
  },
  workspace: {
    display: 'flex',
    width: 'auto',
    alignItems: 'center',
    borderRadius: '6px',
    padding: '8px',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.06)'
    }
  },
  workspaceImage: {
    width: '24px',
    height: '24px',
    borderRadius: '6px',
    '&.avatar': {
      objectFit: 'cover',
    }
  },
  user: {
    borderRadius: '6px',
    padding: '8px',
    cursor: 'pointer',
    color: '#FFF',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.06)'
    }
  },
  more: {
    width: 20,
  },
  section: {
    marginBottom: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
  },
  backBtn: {
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(255, 255, 255, 0.84)',
    margin: '12px 0 24px 0',
    '&:hover': {
      cursor: 'pointer'
    },
    '& svg': {
      width: 18,
      height: 18
    }
  }
}))

const links: Array<ILink> = [
  {
    ref: 'https://www.google.com',
    icon: HomeRoundedIcon,
    caption: 'Go to Feedc\'s Home Page',
  },
  {
    ref: 'https://www.google.com',
    icon: ChatOutlinedIcon,
    caption: 'Help and Feedback',
  },
]

const menu: Array<IMenuSection> = [
  {
    items: [
      {
        icon: '/img/settings-icon.svg',
        caption: 'Settings',
        route: '/settings/posts'
      }
    ]
  },
  {
    title: 'Inventory',
    items: [
      {
        icon: '/img/users-icon.svg',
        caption: 'Users',
        route: '/users'
      },
      {
        icon: '/img/posts-icon.svg',
        caption: 'Posts',
        route: '/posts'
      },
      {
        icon: '/img/feeds-icon.svg',
        caption: 'Feeds',
        route: '/feeds'
      },
    ]
  },
]

const Navbar = (): JSX.Element => {
  const classes = useStyle()
  const history = useHistory()
  const [isSettings, setIsSettings] = useState(false)
  const currentWorkspace = useSelector(getCurrentWorkspace)
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const workspaces: IWorkspaceState = useSelector(getWorkspace)

  const [workspaceMenu, toggleWorkspaceMenu] = useState(false)
  const [userMenu, toggleUserMenu] = useState(false)

  useEffect(() => {
    setIsSettings(history.location.pathname.includes('settings'))
  }, [history.location])

  const handleChangeActive = (id: number): void => {
    dispatch(setActiveId(id))
    sessionStorage.setItem('activeWorkspace', id.toString())
    toggleWorkspaceMenu(false)
  }

  const handleBack = () => history.push('/')

  const open = useSelector(getMenuOpen)

  return (
    <div className={clsx(classes.wrapper, open && 'active')}>
      <section className={classes.info}>
        {isSettings
          ? (
            <div className={classes.backBtn} onClick={handleBack}>
              <ArrowBackIosIcon />
              Settings
            </div>
          )
          : (
            <section className={classes.section}>
              <ClickAwayListener onClickAway={() => { toggleWorkspaceMenu(false) }}>
                <span>
                  <div className={classes.workspace} onClick={() => toggleWorkspaceMenu(!workspaceMenu)}>
                    {currentWorkspace?.photo.original ? (
                      <img className={clsx(classes.workspaceImage, 'avatar')} src={currentWorkspace.photo.original} alt='Workspace avatar' />
                    ) :
                      (
                        <img className={classes.workspaceImage} src='/img/img-preview.svg' alt='Placeholder' />
                      )}
                    <p className={classes.heading}>{currentWorkspace?.title}</p>
                  </div>
                  {workspaceMenu && (
                    <WorkspaceDropdown
                      workspaces={workspaces.data}
                      handleChangeWorkspace={handleChangeActive}
                    />
                  )}
                </span>
              </ClickAwayListener>
              <ClickAwayListener onClickAway={() => { toggleUserMenu(false) }}>
                <span>
                  <div className={classes.user} onClick={() => toggleUserMenu(!userMenu)}>
                    {user.name}
                    <ExpandMoreRoundedIcon className={classes.more} />
                  </div>
                  {userMenu && (
                    <UserDropdown
                      handleClose={() => toggleUserMenu(false)}
                    />
                  )}
                </span>
              </ClickAwayListener>
            </section>
          )}
        {isSettings ? <SettingsMenu /> : <Menu menu={menu} />}
        <ActionLinks links={links} />
        <FeedUsage
          requests={48}
          limit={100}
        />
      </section>
    </div>
  )
}

export default Navbar