import * as actions from 'actions/authActions'
import { IAction, IAuthState } from 'types/redux'

const initialState = {
  user: null,
  error: null,
  isLoading: true
};

const auth = (state: IAuthState = initialState, action: IAction): Object => {
  switch (action.type) {
    case actions.REGISTER_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case actions.REGISTER_USER_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case actions.REGISTER_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actions.LOGIN_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case actions.LOGIN_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.data
      }
    case actions.LOGIN_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actions.GET_AUTH_ME_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case actions.GET_AUTH_ME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.data
      }
    case actions.GET_AUTH_ME_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actions.RESET_AUTH_STORE:
      return {
        ...state,
        ...initialState,
        isLoading: false
      }
    default:
      return state;
  }
};

export default auth;
