import axios from 'axios'
import { Store } from 'redux'

const axiosInstance = axios.create({})

let count = 0
let wasFewReq = false

export const setupInterceptors = (store: Store): void => {
    axiosInstance.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem('accessToken')

            count += 1
            if (count > 1) {
                wasFewReq = true
            }

            if (token) {
                // eslint-disable-next-line no-param-reassign
                config.headers.Authorization = `Bearer ${token}`
            }

            return config;
        }
    );

    axiosInstance.interceptors.response.use(
        (response) => {
            count -= 1
            if (count < 1 && wasFewReq) {
                // dispatch
                store.dispatch({ type: 'RESET_GLOBAL_LOADER', data: false })
                wasFewReq = false
            }

            return response
        },
        (error) => {
            count -= 1

            if (count < 1 && wasFewReq) {
                // dispatch
                store.dispatch({ type: 'RESET_GLOBAL_LOADER', data: false })
                wasFewReq = false
            }
            return Promise.reject((error.response && error.response.data) || 'Something went wrong')
        }
    )
}

export default axiosInstance;
