import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { getWorkspace } from 'selectors'
import { getUserWorkspaceRequest, setActiveId, unsetActiveId } from 'actions/workspaceActions'
import { getPostPropertiesRequest, getUserPropertiesRequest, getPostTypesRequest, getUserTypesRequest } from 'actions/settingsActions'
import Navbar from 'components/Navbar'
import { Redirect } from 'react-router'

type Props = {
  children: JSX.Element
}

const useStyle = makeStyles({
  wrapper: {
    display: 'flex',
    height: '100%',
    background: '#fff'
  }
})

const WorkspaceLayout = ({ children }: Props): JSX.Element => {
  const classes = useStyle()
  const dispatch = useDispatch()
  const workspaceData = useSelector(getWorkspace)

  useEffect(() => {
    dispatch(getUserWorkspaceRequest())
    return () => {
      dispatch(unsetActiveId())
    }
  }, [])

  useEffect(() => {
    const activeId = Number(sessionStorage.getItem('activeWorkspace'))

    if (workspaceData.data && workspaceData.data.length > 0 && !workspaceData.activeId) {
      dispatch(setActiveId(activeId || workspaceData.data[0].id))
    }

    if (workspaceData.activeId) {
      dispatch(getPostPropertiesRequest(workspaceData.activeId))
      dispatch(getPostTypesRequest(workspaceData.activeId))
      dispatch(getUserPropertiesRequest(workspaceData.activeId))
      dispatch(getUserTypesRequest(workspaceData.activeId))
    }
  }, [workspaceData.data, workspaceData.activeId])

  if (workspaceData.data && workspaceData.data.length <= 0) {
    return <Redirect to='/create-workspace' />
  }

  return (
    <div className={classes.wrapper}>
      <Navbar />
      {workspaceData.data && children}
    </div>
  )
}

export default WorkspaceLayout