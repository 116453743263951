import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React, { Fragment } from 'react'
import { useHistory } from 'react-router'
import { IWorkspace } from 'types/workspace'

const useStyle = makeStyles({
  wrapper: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    background: '#FFF',
    borderRadius: 6,
    marginTop: '4px',
  },
  workspaceImage: {
    width: '24px',
    height: '24px',
    borderRadius: '6px',
    marginRight: '8px',
    '&.avatar': {
      objectFit: 'cover',
    }
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 12px',
    cursor: 'pointer',
    color: '#1C1C2E',
    lineHeight: '20px',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)'
    }
  },
  workspaces: {
    padding: '6px 0',
    maxHeight: 200,
    overflowY: 'scroll',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.04)',
    '&::-webkit-scrollbar': {
      display: 'none',
    }
  },
  menu: {
    padding: '6px 0'
  },
  heading: {
    padding: '0 12px',
    color: '#8C90A3',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px',
  }
})

export type Menu = {
  title?: string,
  items: Array<MenuItem>
}

export type MenuItem = {
  caption: string,
  action: () => void
}

export type Props = {
  workspaces: Array<IWorkspace> | null,
  handleChangeWorkspace: (i: number) => void
}

const WorkspaceDropdown = ({ workspaces, handleChangeWorkspace }: Props): JSX.Element => {
  const classes = useStyle()

  const history = useHistory()

  const menu: Array<Menu> = [
    {
      title: 'Workspace',
      items: [
        {
          caption: 'Workspace settings',
          action: () => history.push('/settings/posts')
        },
        {
          caption: 'Create a new workspace',
          action: () => {
            history.push('/create-workspace')
          }
        },
        {
          caption: 'Show keyboard Shortcuts',
          action: () => undefined
        },
        {
          caption: 'Help & Support',
          action: () => undefined
        },
      ]
    }
  ]

  const renderWorkspace = (workspace: IWorkspace): JSX.Element => (
    <div key={workspace.title} className={classes.row} onClick={() => handleChangeWorkspace(workspace.id)}>
      {workspace.photo.original ? (
        <img className={clsx(classes.workspaceImage, 'avatar')} src={workspace.photo.original} alt='Workspace avatar' />
      ) :
        (
          <img className={classes.workspaceImage} src='/img/img-preview.svg' alt='Placeholder' />
        )}
      <p>{workspace.title}</p>
    </div>
  )

  const renderMenu = (menulist: Array<Menu>): JSX.Element => (
    <div className={classes.menu}>
      {menulist.map(m => (
        <Fragment key={m.title}>
          {m.title && <p className={classes.heading}>{m.title}</p>}
          {m.items.map(item => (
            <div key={item.caption} className={classes.row} onClick={item.action}>
              {item.caption}
            </div>
          ))}
        </Fragment>
      ))}
    </div>
  )

  return (
    <section className={classes.wrapper}>
      <div className={classes.workspaces}>
        {workspaces && workspaces.map(w => renderWorkspace(w))}
      </div>
      {renderMenu(menu)}
    </section>
  )
}

export default WorkspaceDropdown