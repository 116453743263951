import TextFieldsRoundedIcon from '@material-ui/icons/TextFieldsRounded'
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded'
import ExposurePlus2RoundedIcon from '@material-ui/icons/ExposurePlus2Rounded'
import DateRangeIcon from '@material-ui/icons/DateRange'
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered'
import { SvgIconProps } from '@material-ui/core'

export interface IMenuItem {
  route: string,
  caption: string,
}

export interface IMenuSection {
  title?: string,
  items: Array<IMenuItem>
}

export const propertyTypes: Array<IPropertyType> = [
  {
    name: 'Text',
    value: 'text',
    icon: TextFieldsRoundedIcon,
    description: 'Just start writing with plain text',
    isNew: false,
    operators: [
      {
        label: 'Is',
        value: '='
      },
      {
        label: 'Is not',
        value: '!='
      },
      {
        label: 'Contains',
        value: '==='
      }
    ]
  },
  {
    name: 'Boolean',
    value: 'boolean',
    icon: CheckCircleRoundedIcon,
    description: 'True/False value',
    isNew: true,
    operators: [
      {
        label: 'Is',
        value: '='
      },
      {
        label: 'Is not',
        value: '!='
      }
    ]
  },
  {
    name: 'Number',
    value: 'number',
    icon: ExposurePlus2RoundedIcon,
    description: 'A number value',
    isNew: false,
    operators: [
      {
        label: 'Is',
        value: '='
      },
      {
        label: 'Is not',
        value: '!='
      },
      {
        label: 'More than',
        value: '>'
      },
      {
        label: 'Less than',
        value: '<'
      }
    ]
  },
  {
    name: 'Date',
    value: 'timestamp',
    icon: DateRangeIcon,
    description: 'A date range',
    isNew: false,
    operators: [
      {
        label: 'Is',
        value: '='
      },
      {
        label: 'Is not',
        value: '!='
      }
    ]
  },
  {
    name: 'JSON',
    value: 'content',
    icon: FormatListNumberedIcon,
    description: 'Content in JSON format',
    isNew: false,
    operators: []
  }
]

export const sortTypes = [
  { label: 'Accending', value: 'ASC' },
  { label: 'Deseeding', value: 'DESC' }
]
export interface IPropertyType {
  name: string,
  value: any,
  icon: (props: SvgIconProps) => JSX.Element,
  description: string,
  isNew: boolean,
  operators: Array<IOperator>
}

export interface IOperator {
  label: string,
  value: string
}

export const settingsMenu: Array<IMenuSection> = [
  {
    title: 'Posts',
    items: [
      {
        caption: 'Post Properties and Types',
        route: '/settings/posts'
      },
      {
        caption: 'Interactions',
        route: '/settings/post-interactions'
      }
    ]
  },
  {
    title: 'Users',
    items: [
      {
        caption: 'User Properties and Types',
        route: '/settings/users'
      },
      {
        caption: 'Interactions',
        route: '/settings/user-interactions'
      }
    ]
  },
]

export const selectDateOptions = [
  {
    value: 'last_30_minutes',
    label: 'Last 30 minutes'
  },
  {
    value: 'last_hour',
    label: 'Last hour'
  },
  {
    value: 'last_24_hours',
    label: 'Last 24 hours'
  },
  {
    value: 'last_7_days',
    label: 'Last 7 days'
  },
  {
    value: 'last_30_days',
    label: 'Last 30 days'
  },
  {
    value: 'last_365_days',
    label: 'Last 365 days'
  },
  {
    value: 'previous_week',
    label: 'Previous week'
  },
  {
    value: 'current_week',
    label: 'Current week'
  },
  {
    value: 'previous_month',
    label: 'Previous month'
  },
  {
    value: 'current_month',
    label: 'Current month'
  },
]