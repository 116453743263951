import { IAction, IError } from 'types/redux'
import { IWorkspace } from 'types/workspace'
import { ICreateWorkspaceValue } from 'components/CreateWorkspace'

export const GET_USER_WORKSPACE_REQUEST = 'GET_USER_WORKSPACE_REQUEST'
export const GET_USER_WORKSPACE_SUCCESS = 'GET_USER_WORKSPACE_SUCCESS'
export const GET_USER_WORKSPACE_ERROR = 'GET_USER_WORKSPACE_ERROR'

export const CREATE_WORKSPACE_REQUEST = 'CREATE_WORKSPACE_REQUEST'
export const CREATE_WORKSPACE_SUCCESS = 'CREATE_WORKSPACE_SUCCESS'
export const CREATE_WORKSPACE_ERROR = 'CREATE_WORKSPACE_ERROR'

export const SET_ACTIVE_ID = 'SET_ACTIVE_ID'
export const UNSET_ACTIVE_ID = 'UNSET_ACTIVE_ID'

export const getUserWorkspaceRequest = (): IAction => ({
  type: GET_USER_WORKSPACE_REQUEST
})

export const getUserWorkspaceSuccess = (data: IWorkspace): IAction => ({
  type: GET_USER_WORKSPACE_SUCCESS,
  data
})

export const getUserWorkspaceError = (error: IError): IAction => ({
  type: GET_USER_WORKSPACE_ERROR,
  error
})

export const createWorkspaceRequest = (data: ICreateWorkspaceValue): IAction => ({
  type: CREATE_WORKSPACE_REQUEST,
  data
})

export const createWorkspaceSuccess = (): IAction => ({
  type: CREATE_WORKSPACE_SUCCESS
})

export const createWorkspaceError = (error: IError): IAction => ({
  type: CREATE_WORKSPACE_ERROR,
  error
})

export const setActiveId = (data: number): IAction => ({
  type: SET_ACTIVE_ID,
  data
})

export const unsetActiveId = (): IAction => ({
  type: UNSET_ACTIVE_ID,
})