import React from 'react'
import { LinearProgress, makeStyles } from '@material-ui/core'

const useStyle = makeStyles({
  usage: {
    padding: '16px',
    borderRadius: '10px',
    background: '#28283D',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#8C90A3',
    display: 'inline-block'
  },
  heading: {
    color: '#FFF',
    fontWeight: 'bold',
    marginBottom: '4px',
  },
  progress: {
    margin: '16px 0',
    width: '216px',
    height: '4px',
    borderRadius: '16px',
    background: 'rgba(255, 255, 255, 0.06)',
    '&>div': {
      borderRadius: '16px',
      background: '#FFF',
    }
  },
  link: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontWeight: 600,
  },
})

export interface FeedUsageProps {
  requests: number,
  limit?: number
}

const FeedUsage = ({ requests, limit }: FeedUsageProps): JSX.Element => {
  const classes = useStyle()

  const percentage = limit ? requests / limit * 100 : 100;

  return (
    <div className={classes.usage}>
      <p className={classes.heading}>Your feeds usage</p>
      {limit
        ? (
          <p>{`${requests} request out of ${limit}`}</p>
        )
        : (
          <p>{`${requests} request, unlimited access`}</p>
        )}
      <LinearProgress
        className={classes.progress}
        variant="determinate"
        value={percentage}
      />
      {limit && <a className={classes.link} href="https://www.google.com">Upgrade to go unlimited</a>}
    </div>
  )
}

export default FeedUsage