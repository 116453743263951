/* eslint-disable no-param-reassign */
import { IAction, IPropertiesState } from "types/redux"
import * as actions from 'actions/settingsActions'
import { EPropertyStatus, IPageProperty } from "types/settings"

const defaultProperty: IPageProperty = {
  name: '',
  type: '',
  required: false,
  status: EPropertyStatus.new,
  sort: 10000
}

const initialState = {
  items: [],
  isLoading: false,
  error: null
}

const userPropertiesReducer = (state: IPropertiesState = initialState, action: IAction): Object => {
  switch (action.type) {
    case actions.ADD_USER_PROPERTY:
      return {
        ...state,
        items: [
          ...state.items,
          {
            ...defaultProperty,
            customerType: action.data,
            id: state.items.length + action.data
          }
        ]
      }
    case actions.REMOVE_USER_PROPERTY:
      return {
        ...state,
        items: state.items?.filter(prop => prop.id !== action.data)
      }
    case actions.UPDATE_USER_PROPERTY:
      const ditems = [...state.items?.map(x => {
        if (x.id === action.data.property.id) {
          return {
            ...action.data.property,
            status: action.data.property.status === 0 ? EPropertyStatus.new : EPropertyStatus.updated
          }
        }
        return x
      })]

      return {
        ...state,
        items: ditems
      }
    case actions.GET_USER_PROPERTIES_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actions.GET_USER_PROPERTIES_SUCCESS:
      const requiredList = action.data
        .filter((x: IPageProperty) => x.required)
        .map((x: IPageProperty) => ({ ...x, status: EPropertyStatus.loaded }))
      const nonRequiredList = action.data
        .filter((x: IPageProperty) => !x.required)
        .map((x: IPageProperty) => ({ ...x, status: EPropertyStatus.loaded }))
      return {
        ...state,
        isLoading: false,
        items: [...requiredList, ...nonRequiredList]
      }
    case actions.GET_USER_PROPERTIES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.data
      }
    case actions.DELETE_USER_PROPERTY_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case actions.DELETE_USER_PROPERTY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: state.items.filter((x) => x.id !== action.data)
      }
    case actions.DELETE_USER_PROPERTY_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      }
    case actions.SAVE_USER_PROPERTIES_CHANGES_SUCCESS:
      const newItems = state.items?.map(x => ({
        ...x,
        status: EPropertyStatus.loaded
      }))

      return {
        ...state,
        isLoading: false,
        items: newItems
      }
    case actions.SUBMIT_USER_PROPERTY_SUCCESS:
      const itemsAfterSubmit = state.items.map(x => {
        return x.name === action.data.name ? ({
          ...action.data,
          status: EPropertyStatus.loaded
        }) : x
      })
      return {
        ...state,
        items: itemsAfterSubmit
      }
    case actions.HANDLE_CUSTOMER_PROPERTIES_REORDER:
      const reordered = action.data.items.map((el: IPageProperty, i: number) => {
        el.sort = i + 1
        el.status = EPropertyStatus.updated
        return el
      })

      const restProperties = state.items.filter(el => el.customerType !== action.data.typeId)

      return {
        ...state,
        items: [...reordered, ...restProperties]
      }
    default:
      return state
  }
}

export default userPropertiesReducer