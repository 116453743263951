import { put, takeLatest, call } from "redux-saga/effects"
import { IAction } from 'types/redux'
import * as actions from 'actions/workspaceActions'
import { resetAllData } from 'actions/globalActions'
import * as api from 'api/workspaceApi'
import { refreshAccessToken } from './authSaga'

function* getAllUserWorkspaces() {
  try {
    const response = yield call(api.getAllUserWorkspaces)

    yield put(actions.getUserWorkspaceSuccess(response.data.pages))
  } catch (e: any) {
    yield put(actions.getUserWorkspaceError(e.errors))
  }
}

function* createWorkspace({ data }: IAction) {
  try {
    const response = yield call(api.createWorkspace, data)
    yield put(actions.setActiveId(response.data.page.id))
    sessionStorage.setItem('activeWorkspace', response.data.page.id.toString())
    yield put(resetAllData())
    yield put(actions.createWorkspaceSuccess())
  } catch (e: any) {
    if (e.errors && e.errors[0].code === 112) {
      yield call(refreshAccessToken)
      yield put(actions.createWorkspaceRequest(data))
    } else {
      yield put(actions.createWorkspaceError(e.errors))
    }
  }
}

function* workspaceSaga(): any {
  yield takeLatest("GET_USER_WORKSPACE_REQUEST", getAllUserWorkspaces)
  yield takeLatest("CREATE_WORKSPACE_REQUEST", createWorkspace)
}

export default workspaceSaga
