import axiosInstance from 'utils/axios'
import axios from 'axios'
import { IValues } from '../types/authView'
import { API_AUTH_BASE_URL } from '../config'

export const registerUser = (data: IValues): Promise<any> => axiosInstance.post(`${API_AUTH_BASE_URL}/auth/signup`, data)

export const loginUser = (data: IValues): Promise<any> => axiosInstance.post(`${API_AUTH_BASE_URL}/auth/signin`, data)

export const getAuthMe = (): Promise<any> => axiosInstance.get(`${API_AUTH_BASE_URL}/users/me`)

export const refreshAccessToken = (): Promise<any> => axios.get(`${API_AUTH_BASE_URL}/auth/refreshToken`)