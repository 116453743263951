import * as actions from 'actions/globalActions'
import { IAction, IGlobalState } from 'types/redux'

const initialState = {
  isGlobalLoading: true,
  toast: {
    show: false
  }
};

const auth = (state: IGlobalState = initialState, action: IAction): Object => {
  switch (action.type) {
    case actions.RESET_GLOBAL_LOADER:
      return {
        ...state,
        isGlobalLoading: action.data
      }
    case actions.SHOW_TOAST:
      return {
        ...state,
        toast: {
          show: true,
          data: action.data
        }
      }
    case actions.HIDE_TOAST:
      return {
        ...state,
        toast: {
          show: false
        }
      }
    default:
      return state;
  }
};

export default auth;
