import React from 'react'
import { makeStyles, Slide } from '@material-ui/core'
import clsx from 'clsx'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { settingsMenu, IMenuSection, IMenuItem } from '../../constants'

const useStyle = makeStyles({
  menu: {
    flex: 1,
    overflowY: 'scroll',
    '-ms-owerflow-style': 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    }
  },
  section: {
    marginBottom: '24px',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    color: 'rgba(255, 255, 255, 0.54)',
    fontSize: 12,
    '& img': {
      margin: '0 8px',
      width: 20,
      height: 20
    }
  },
  iconWrapper: {
    marginRight: '8px',
    width: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  item: {
    padding: '8px 36px',
    margin: '2px 0',
    borderRadius: '3px',
    textDecoration: 'none',
    color: '#FFF',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.06)',
    },
    '&.active': {
      background: 'rgba(255, 255, 255, 0.06)',
      color: '#FFF',
    }
  }
})

const SettingsMenu = (): JSX.Element => {
  const classes = useStyle()
  const history = useHistory()

  const renderMenu = () => (
    settingsMenu.map((menuItem) => (
      <section className={classes.section} key={menuItem.title}>
        {menuItem.title && (
          <div className={classes.titleWrapper}>
            <img src='/img/section-icon.svg' alt='Section title' />
            <p>{menuItem.title}</p>
          </div>
        )}
        {renderMenuItems(menuItem)}
      </section>
    ))
  )

  const renderMenuItems = (menuItem: IMenuSection) => (
    menuItem.items.map((item: IMenuItem) => (
      <Link
        to={item.route}
        className={clsx(classes.item, history.location.pathname === item.route && 'active')}
        key={item.route}
      >
        <p>{item.caption}</p>
      </Link>
    ))
  )

  return (
    <Slide
      direction='left'
      in
      mountOnEnter
      unmountOnExit
    >
      <div className={classes.menu}>
        {renderMenu()}
      </div>
    </Slide>
  )
}

export default SettingsMenu