import React from 'react'
import { Redirect } from 'react-router-dom'
import useAuth from '../hooks/useAuth'

type Props = {
  children: JSX.Element
}

const GuestGuard = ({ children }: Props): JSX.Element => {
  const { isAuth } = useAuth();

  if (isAuth) {
    return <Redirect to="/" />;
  }

  return (
    <>
      {children}
    </>
  );
};

export default GuestGuard;
