import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import createSagaMiddleware from "redux-saga"
import { composeWithDevTools } from 'redux-devtools-extension'
import { setupInterceptors } from 'utils/axios'
import rootReducer from './reducers'
import saga from './sagas'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AuthProvider } from './contexts/AuthContext'
import GlobalStyles from './components/GlobalStyles'

const sagaMiddleware = createSagaMiddleware()
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)))
sagaMiddleware.run(saga)

setupInterceptors(store)

ReactDOM.render(
  <Provider store={store}>
    <GlobalStyles />
    <AuthProvider>
      <App />
    </AuthProvider>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
