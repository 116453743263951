import React from 'react'
import Lottie from 'react-lottie-player'
import { makeStyles } from '@material-ui/core'
import lottieJson from '../assets/lottieJson.json'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: '#fff',
    top: 0,
    zIndex: 100
  },
  img: {
    position: 'absolute',
    color: '#1C1C2E'
  }
}));

const FullscreenLoader = (): JSX.Element => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Lottie
        loop
        animationData={lottieJson}
        play
        style={{ width: 150, height: 150 }}
        speed={1}
      />
    </div>
  )
}

export default FullscreenLoader