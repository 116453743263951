import * as actions from 'actions/workspaceActions'
import { IAction, IWorkspaceState } from 'types/redux'

const initialState = {
  data: null,
  activeId: null,
  error: null,
  isLoading: true,
  isCreated: false
}

const workspace = (state: IWorkspaceState = initialState, action: IAction): Object => {
  switch (action.type) {
    case actions.GET_USER_WORKSPACE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
        isCreated: false
      }
    case actions.GET_USER_WORKSPACE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data
      }
    case actions.GET_USER_WORKSPACE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actions.CREATE_WORKSPACE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case actions.CREATE_WORKSPACE_SUCCESS:
      return {
        ...state,
        isLoading: true,
        isCreated: true,
        data: null
      }
    case actions.CREATE_WORKSPACE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        isCreated: false
      }
    case actions.SET_ACTIVE_ID:
      return {
        ...state,
        isLoading: false,
        activeId: action.data
      }
    case actions.UNSET_ACTIVE_ID:
      return {
        ...state,
        isLoading: false,
        activeId: null
      }
    default:
      return state
  }
}

export default workspace
