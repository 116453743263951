import { IAction, IFeedsState } from "types/redux"
import * as actions from 'actions/feedsActions'

const initialState = {
  isLoading: false,
  error: null,
  feeds: [],
  pageToken: '',
  hasMore: false,
  feedsCount: 0,
  feed: null
}

const feeds = (state: IFeedsState = initialState, action: IAction): Object => {
  switch(action.type){
    case actions.GET_FEEDS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case actions.GET_FEEDS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        ...action.data
      }
    case actions.GET_FEEDS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actions.GET_FEED_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case actions.GET_FEED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        feed: action.data
      }
    case actions.GET_FEED_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actions.UPDATE_FEED_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case actions.UPDATE_FEED_SUCCESS:
      const updatedFeeds = state.feeds.map(f => f.id === action.data.id ? action.data : f)
      return {
        ...state,
        isLoading: false,
        error: null,
        feeds: updatedFeeds
      }
    case actions.UPDATE_FEED_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actions.REMOVE_FEED_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case actions.REMOVE_FEED_SUCCESS:
      const feedsAfterRemove = state.feeds.filter(x => x.id !== action.data)
      return {
        ...state,
        isLoading: false,
        feeds: feedsAfterRemove,
        error: null,
      }
    case actions.REMOVE_FEED_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actions.CREATE_FEED_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case actions.CREATE_FEED_SUCCESS:
      return {
        ...state,
        feeds: [...state.feeds, action.data],
        isLoading: false,
        error: null,
      }
    case actions.CREATE_FEED_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default feeds