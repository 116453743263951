import { IAction, IError } from "types/redux"
import { DeletePropertyProps, IPageProperty, IPageType, IPropertyResponse } from "types/settings"

export const ADD_USER_PROPERTY = 'ADD_USER_PROPERTY'
export const REMOVE_USER_PROPERTY = 'REMOVE_USER_PROPERTY'
export const UPDATE_USER_PROPERTY = 'UPDATE_USER_PROPERTY'

export const GET_USER_PROPERTIES_REQUEST = 'GET_USER_PROPERTIES_REQUEST'
export const GET_USER_PROPERTIES_SUCCESS = 'GET_USER_PROPERTIES_SUCCESS'
export const GET_USER_PROPERTIES_ERROR = 'GET_USER_PROPERTIES_ERROR'

export const DELETE_USER_PROPERTY_REQUEST = 'DELETE_USER_PROPERTY_REQUEST'
export const DELETE_USER_PROPERTY_SUCCESS = 'DELETE_USER_PROPERTY_SUCCESS'
export const DELETE_USER_PROPERTY_ERROR = 'DELETE_USER_PROPERTY_ERROR'

export const SAVE_USER_PROPERTIES_CHANGES = 'SAVE_USER_PROPERTIES_CHANGES'
export const SAVE_USER_PROPERTIES_CHANGES_SUCCESS = 'SAVE_USER_PROPERTIES_CHANGES_SUCCESS'
export const SAVE_USER_PROPERTIES_CHANGES_ERROR = 'SAVE_USER_PROPERTIES_CHANGES_ERROR'

export const ADD_POST_PROPERTY = 'ADD_POST_PROPERTY'
export const REMOVE_POST_PROPERTY = 'REMOVE_POST_PROPERTY'
export const UPDATE_POST_PROPERTY = 'UPDATE_POST_PROPERTY'

export const SET_ACTIVE_POST_PROPERTY = 'SET_ACTIVE_POST_PROPERTY'
export const UNSET_ACTIVE_POST_PROPERTY = 'UNSET_ACTIVE_POST_PROPERTY'

export const GET_POST_PROPERTIES_REQUEST = 'GET_POST_PROPERTIES_REQUEST'
export const GET_POST_PROPERTIES_SUCCESS = 'GET_POST_PROPERTIES_SUCCESS'
export const GET_POST_PROPERTIES_ERROR = 'GET_POST_PROPERTIES_ERROR'

export const DELETE_POST_PROPERTY_REQUEST = 'DELETE_POST_PROPERTY_REQUEST'
export const DELETE_POST_PROPERTY_SUCCESS = 'DELETE_POST_PROPERTY_SUCCESS'
export const DELETE_POST_PROPERTY_ERROR = 'DELETE_POST_PROPERTY_ERROR'

export const SAVE_POST_PROPERTIES_CHANGES = 'SAVE_POST_PROPERTIES_CHANGES'
export const SAVE_POST_PROPERTIES_CHANGES_SUCCESS = 'SAVE_POST_PROPERTIES_CHANGES_SUCCESS'
export const SAVE_POST_PROPERTIES_CHANGES_ERROR = 'SAVE_POST_PROPERTIES_CHANGES_ERROR'

export const SUBMIT_USER_PROPERTY_SUCCESS = "SUBMIT_USER_PROPERTY_SUCCESS"
export const SUBMIT_POST_PROPERTY_SUCCESS = "SUBMIT_POST_PROPERTY_SUCCESS"

export const GET_POST_TYPES_REQUEST = 'GET_POST_TYPES_REQUEST'
export const GET_POST_TYPES_SUCCESS = 'GET_POST_TYPES_SUCCESS'
export const GET_POST_TYPES_ERROR = 'GET_POST_TYPES_ERROR'

export const GET_USER_TYPES_REQUEST = 'GET_USER_TYPES_REQUEST'
export const GET_USER_TYPES_SUCCESS = 'GET_USER_TYPES_SUCCESS'
export const GET_USER_TYPES_ERROR = 'GET_USER_TYPES_ERROR'

export const POST_TYPE_TITLE_CHANGE = 'POST_TYPE_TITLE_CHANGE'

export const CUSTOMER_TYPE_TITLE_CHANGE = 'CUSTOMER_TYPE_TITLE_CHANGE'

export const UPDATE_POST_TYPE_REQUEST = 'UPDATE_POST_TYPE_REQUEST'
export const UPDATE_POST_TYPE_SUCCESS = 'UPDATE_POST_TYPE_SUCCESS'
export const UPDATE_POST_TYPE_ERROR = 'UPDATE_POST_TYPE_ERROR'

export const CREATE_POST_TYPE_REQUEST = 'CREATE_POST_TYPE_REQUEST'
export const CREATE_POST_TYPE_SUCCESS = 'CREATE_POST_TYPE_SUCCESS'
export const CREATE_POST_TYPE_ERROR = 'CREATE_POST_TYPE_ERROR'

export const UPDATE_CUSTOMER_TYPE_REQUEST = 'UPDATE_CUSTOMER_TYPE_REQUEST'
export const UPDATE_CUSTOMER_TYPE_SUCCESS = 'UPDATE_CUSTOMER_TYPE_SUCCESS'
export const UPDATE_CUSTOMER_TYPE_ERROR = 'UPDATE_CUSTOMER_TYPE_ERROR'

export const CREATE_CUSTOMER_TYPE_REQUEST = 'CREATE_CUSTOMER_TYPE_REQUEST'
export const CREATE_CUSTOMER_TYPE_SUCCESS = 'CREATE_CUSTOMER_TYPE_SUCCESS'
export const CREATE_CUSTOMER_TYPE_ERROR = 'CREATE_CUSTOMER_TYPE_ERROR'

export const DELETE_POST_TYPE_REQUEST = 'DELETE_POST_TYPE_REQUEST'
export const DELETE_POST_TYPE_SUCCESS = 'DELETE_POST_TYPE_SUCCESS'
export const DELETE_POST_TYPE_ERROR = 'DELETE_POST_TYPE_ERROR'

export const DELETE_CUSTOMER_TYPE_REQUEST = 'DELETE_CUSTOMER_TYPE_REQUEST'
export const DELETE_CUSTOMER_TYPE_SUCCESS = 'DELETE_CUSTOMER_TYPE_SUCCESS'
export const DELETE_CUSTOMER_TYPE_ERROR = 'DELETE_CUSTOMER_TYPE_ERROR'

export const HANDLE_POST_PROPERTIES_REORDER = 'HANDLE_POST_PROPERTIES_REORDER'
export const HANDLE_CUSTOMER_PROPERTIES_REORDER = 'HANDLE_CUSTOMER_PROPERTIES_REORDER'

export const submitPostPropertySuccess = (data: IPropertyResponse): IAction => ({
  type: SUBMIT_POST_PROPERTY_SUCCESS,
  data
})

export const submitUserPropertySuccess = (data: IPropertyResponse): IAction => ({
  type: SUBMIT_USER_PROPERTY_SUCCESS,
  data
})

export const addUserProperty = (data: number): IAction => ({
  type: ADD_USER_PROPERTY,
  data
})

export const removeUserProperty = (data: number): IAction => ({
  type: REMOVE_USER_PROPERTY,
  data
})

export const updateUserProperty = (data: IPageProperty, id: number): IAction => {
  return {
    type: UPDATE_USER_PROPERTY,
    data: {
      property: data,
      id
    }
  }
}

export const getUserPropertiesRequest = (data: number): IAction => ({
  type: GET_USER_PROPERTIES_REQUEST,
  data
})

export const getUserPropertiesSuccess = (data: Array<IPageProperty>): IAction => ({
  type: GET_USER_PROPERTIES_SUCCESS,
  data
})

export const getUserPropertiesError = (error: IError): IAction => ({
  type: GET_USER_PROPERTIES_ERROR,
  error
})

export const deleteUserPropertyRequest = (data: DeletePropertyProps): IAction => ({
  type: DELETE_USER_PROPERTY_REQUEST,
  data
})

export const deleteUserPropertySuccess = (data: number): IAction => {
  return {
    type: DELETE_USER_PROPERTY_SUCCESS,
    data
  }
}

export const deleteUserPropertyError = (error: IError): IAction => ({
  type: DELETE_USER_PROPERTY_ERROR,
  error
})

export const saveUserPropertyChangesRequest = (data: Array<IPageProperty>): IAction => {
  return {
    type: SAVE_USER_PROPERTIES_CHANGES,
    data
  }
}

export const saveUserPropertyChangesSuccess = (): IAction => ({
  type: SAVE_USER_PROPERTIES_CHANGES_SUCCESS
})

export const saveUserPropertyChangesError = (e: IError): IAction => ({
  type: SAVE_USER_PROPERTIES_CHANGES_ERROR,
  error: e
})

export const addPostProperty = (data: number): IAction => ({
  type: ADD_POST_PROPERTY,
  data
})

export const removePostProperty = (data: number): IAction => ({
  type: REMOVE_POST_PROPERTY,
  data
})

export const updatePostProperty = (data: IPageProperty, id: number): IAction => {
  return {
    type: UPDATE_POST_PROPERTY,
    data: {
      property: data,
      id
    }
  }
}

export const setActivePostProperty = (data: number): IAction => ({
  type: SET_ACTIVE_POST_PROPERTY,
  data
})

export const unsetActivePostProperty = (): IAction => ({
  type: UNSET_ACTIVE_POST_PROPERTY,
})

export const getPostPropertiesRequest = (data: number): IAction => ({
  type: GET_POST_PROPERTIES_REQUEST,
  data
})

export const getPostPropertiesSuccess = (data: Array<IPageProperty>): IAction => ({
  type: GET_POST_PROPERTIES_SUCCESS,
  data
})

export const getPostPropertiesError = (error: IError): IAction => ({
  type: GET_POST_PROPERTIES_ERROR,
  error
})

export const deletePostPropertyRequest = (data: DeletePropertyProps): IAction => ({
  type: DELETE_POST_PROPERTY_REQUEST,
  data
})

export const deletePostPropertySuccess = (data: number): IAction => {
  return {
    type: DELETE_POST_PROPERTY_SUCCESS,
    data
  }
}

export const deletePostPropertyError = (error: IError): IAction => ({
  type: DELETE_POST_PROPERTY_ERROR,
  error
})

export const savePostPropertyChangesRequest = (data: Array<IPageProperty>): IAction => {
  return {
    type: SAVE_POST_PROPERTIES_CHANGES,
    data
  }
}

export const savePostPropertyChangesSuccess = (): IAction => ({
  type: SAVE_POST_PROPERTIES_CHANGES_SUCCESS
})

export const savePostPropertyChangesError = (e: IError): IAction => ({
  type: SAVE_POST_PROPERTIES_CHANGES_ERROR,
  error: e
})

export const getPostTypesRequest = (data: number): IAction => ({
  type: GET_POST_TYPES_REQUEST,
  data
})

export const getPostTypesSuccess = (data: IPageType[]): IAction => ({
  type: GET_POST_TYPES_SUCCESS,
  data
})

export const getPostTypesError = (error: IError): IAction => ({
  type: GET_POST_TYPES_ERROR,
  error
})

export const getUserTypesRequest = (data: number): IAction => ({
  type: GET_USER_TYPES_REQUEST,
  data
})

export const getUserTypesSuccess = (data: IPageType[]): IAction => ({
  type: GET_USER_TYPES_SUCCESS,
  data
})

export const getUserTypesError = (error: IError): IAction => ({
  type: GET_USER_TYPES_ERROR,
  error
})

export const postTypeTitleChange = (data: IPageType): IAction => ({
  type: POST_TYPE_TITLE_CHANGE,
  data
})

export const customerTypeTitleChange = (data: IPageType): IAction => ({
  type: CUSTOMER_TYPE_TITLE_CHANGE,
  data
})

export const updatePostTypeRequest = (data: IPageType): IAction => ({
  type: UPDATE_POST_TYPE_REQUEST,
  data
})

export const updatePostTypeSuccess = (data: IPageType): IAction => ({
  type: UPDATE_POST_TYPE_SUCCESS,
  data
})

export const updatePostTypeError = (error: IError): IAction => ({
  type: UPDATE_POST_TYPE_ERROR,
  error
})

export const createPostTypeRequest = (data: IPageType): IAction => ({
  type: CREATE_POST_TYPE_REQUEST,
  data
})

export const createPostTypeSuccess = (data: IPageType): IAction => ({
  type: CREATE_POST_TYPE_SUCCESS,
  data
})

export const createPostTypeError = (error: IError): IAction => ({
  type: CREATE_POST_TYPE_ERROR,
  error
})

export const updateCustomerTypeRequest = (data: IPageType): IAction => ({
  type: UPDATE_CUSTOMER_TYPE_REQUEST,
  data
})

export const updateCustomerTypeSuccess = (data: IPageType): IAction => ({
  type: UPDATE_CUSTOMER_TYPE_SUCCESS,
  data
})

export const updateCustomerTypeError = (error: IError): IAction => ({
  type: UPDATE_CUSTOMER_TYPE_ERROR,
  error
})

export const createCustomerTypeRequest = (data: IPageType): IAction => ({
  type: CREATE_CUSTOMER_TYPE_REQUEST,
  data
})

export const createCustomerTypeSuccess = (data: IPageType): IAction => ({
  type: CREATE_CUSTOMER_TYPE_SUCCESS,
  data
})

export const createCustomerTypeError = (error: IError): IAction => ({
  type: CREATE_CUSTOMER_TYPE_ERROR,
  error
})

export const deletePostTypeRequest = (data: number): IAction => ({
  type: DELETE_POST_TYPE_REQUEST,
  data
})

export const deletePostTypeSuccess = (data: number): IAction => ({
  type: DELETE_POST_TYPE_SUCCESS,
  data
})

export const deletePostTypeError = (error: IError): IAction => ({
  type: DELETE_POST_TYPE_ERROR,
  error
})

export const deleteCustomerTypeRequest = (data: number): IAction => ({
  type: DELETE_CUSTOMER_TYPE_REQUEST,
  data
})

export const deleteCustomerTypeSuccess = (data: number): IAction => ({
  type: DELETE_CUSTOMER_TYPE_SUCCESS,
  data
})

export const deleteCustomerTypeError = (error: IError): IAction => ({
  type: DELETE_CUSTOMER_TYPE_ERROR,
  error
})

interface IReorder {
  items: IPageProperty[],
  typeId: number
}

export const handlePostPropertiesReorder = ({ items, typeId }: IReorder): IAction => ({
  type: HANDLE_POST_PROPERTIES_REORDER,
  data: {
    items,
    typeId
  }
})

export const handleCustomerPropertiesReorder = ({ items, typeId }: IReorder): IAction => ({
  type: HANDLE_CUSTOMER_PROPERTIES_REORDER,
  data: {
    items,
    typeId
  }
})