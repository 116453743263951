import axios from 'utils/axios'
import { ICreatePostRequest, IPostRequestData } from 'types/posts'
import { API_POSTS_BASE_URL } from '../config'

export const getPosts = (workspaceId: number, token?: string): Promise<any> =>
  axios.get(`${API_POSTS_BASE_URL}/pages/${workspaceId}/posts${token ? `?pageToken=${token}` : ''}`)

export const getPost = (data: IPostRequestData): Promise<any> => axios.get(`${API_POSTS_BASE_URL}/pages/${data.workspaceId}/posts/${data.postId}`)

export const createPost = (workspaceId: number, data: ICreatePostRequest): Promise<any> => axios.post(`${API_POSTS_BASE_URL}/pages/${workspaceId}/posts`, data)

export const setPostStatus = (data: IPostRequestData, status: string): Promise<any> => axios.post(`${API_POSTS_BASE_URL}/pages/${data.workspaceId}/posts/${data.postId}/status`, { status })

export const deletePost = (workspaceId: number, postId: number): Promise<any> => axios.delete(`${API_POSTS_BASE_URL}/pages/${workspaceId}/posts/${postId}`)

export const updatePost = (workspaceId: number, postId: number, body: object): Promise<any> => axios.put(`${API_POSTS_BASE_URL}/pages/${workspaceId}/posts/${postId}`, body)