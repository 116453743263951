import React from 'react';
import { Redirect } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

type Props = {
  children: JSX.Element
}

const AuthGuard = ({ children }: Props): JSX.Element => {
  const { isAuth } = useAuth();

  if (!isAuth) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      {children}
    </>
  );
};

export default AuthGuard;