import React, {
  Suspense,
  Fragment,
  lazy,
} from 'react'
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom'
import WorkspaceLayout from 'layouts/Workspace'
import AuthGuard from './components/AuthGuard'
import GuestGuard from './components/GuestGuard'

type Props = {
  children: JSX.Element
}

type Routes = {
  exact?: boolean,
  guard?: ({ children }: Props) => JSX.Element,
  path: string,
  component?: React.ElementType | null,
  layout?: React.ElementType,
  // eslint-disable-next-line
  routes?: Array<any>
}

const routes: Routes[] = [
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('./views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('./views/auth/RegisterView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/verify',
    component: lazy(() => import('./views/auth/VerifyEmail'))
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/create-workspace',
    component: lazy(() => import('./components/CreateWorkspace'))
  },
  {
    guard: AuthGuard,
    layout: WorkspaceLayout,
    path: '/',
    routes: [
      {
        exact: true,
        guard: AuthGuard,
        path: '/feeds',
        component: lazy(() => import('./views/feeds'))
      },
      {
        exact: true,
        guard: AuthGuard,
        path: '/feeds/details',
        component: lazy(() => import('./views/feeds/InternalPage'))
      },
      {
        exact: true,
        guard: AuthGuard,
        path: '/users',
        component: lazy(() => import('./views/users'))
      },
      {
        exact: true,
        guard: AuthGuard,
        path: '/users/details',
        component: lazy(() => import('./views/users/InternalPage'))
      },
      {
        exact: true,
        guard: AuthGuard,
        path: '/posts',
        component: lazy(() => import('./views/posts'))
      },
      {
        exact: true,
        guard: AuthGuard,
        path: '/posts/details',
        component: lazy(() => import('./views/posts/InternalPage'))
      },
      {
        exact: true,
        guard: AuthGuard,
        path: '/settings/posts',
        component: lazy(() => import('./views/settings/Posts'))
      },
      {
        exact: true,
        guard: AuthGuard,
        path: '/settings/post-interactions',
        component: lazy(() => import('./views/settings/PostInteractions'))
      },
      {
        exact: true,
        guard: AuthGuard,
        path: '/settings/users',
        component: lazy(() => import('./views/settings/Users'))
      },
      {
        exact: true,
        guard: AuthGuard,
        path: '/settings/user-interactions',
        component: lazy(() => import('./views/settings/UserInteractions'))
      },
      {
        exact: true,
        guard: AuthGuard,
        path: '/',
        component: () => <Redirect to='/users' />
      }
    ]
  }
];

export const renderRoutes = (routesArr: Routes[]): JSX.Element => (
  <Suspense fallback={<></>}>
    <Switch>
      {routesArr.map((route) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            render={(props): JSX.Element => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : Component && <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

export default routes;
