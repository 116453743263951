/* eslint-disable no-param-reassign */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { combineReducers } from 'redux'
import { IAction, IState } from 'types/redux'
import authReducer from './authReducer'
import workspaceReducer from './workspaceReducer'
import usersReducer from './usersReducer'
import settingsReducer from './Settings'
import postsReducer from './postReducer'
import globalReducer from './globalReducer'
import feedsReducer from './feedsReducer'
import responsiveReducer from './responsiveReducer'

const appReducer = combineReducers({
    auth: authReducer,
    workspace: workspaceReducer,
    users: usersReducer,
    settings: settingsReducer,
    posts: postsReducer,
    global: globalReducer,
    feeds: feedsReducer,
    responsive: responsiveReducer
})

const rootReducer = (state: IState | undefined, action: IAction): IState => {
    if (action.type === 'LOGOUT_USER_REQUEST') {
        state = undefined
    }

    if (action.type === 'RESET_ALL_DATA') {
        state.feeds.feeds = []
        state.posts.items = []
        state.users.data = []
        state.settings = undefined
    }

    return appReducer(state, action)
}

export default rootReducer