import * as actions from 'actions/settingsActions'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { IAction } from 'types/redux'
import * as api from 'api/settingsApi'
import { EPropertyStatus } from 'types/settings'
import { getWorkspaceActiveId } from 'selectors'
import { refreshAccessToken } from './authSaga'

function* getUserProperties({ data }: IAction) {
  try {
    const response = yield call(api.getUserProperties, data)
    yield put(actions.getUserPropertiesSuccess(response.data.properties))
  } catch (e: any) {
    if (e.errors[0].code === 112) {
      yield call(refreshAccessToken)
      yield put(actions.getUserPropertiesRequest(data))
    } else {
      yield put(actions.getUserPropertiesError(e))
    }
  }
}

function* deleteUserProperty({ data }: IAction) {
  try {
    yield call(api.deleteUserProperty, data.workspaceId, data.id)

    yield put(actions.deleteUserPropertySuccess(data.id))
  } catch (e: any) {
    if (e.errors[0].code === 112) {
      yield call(refreshAccessToken)
      yield put(actions.deleteUserPropertyRequest(data))
    } else {
      yield put(actions.deleteUserPropertyError(e))
    }
  }
}

function* getPostProperties({ data }: IAction) {
  try {
    const response = yield call(api.getPostProperties, data)
    yield put(actions.getPostPropertiesSuccess(response.data.properties))
  } catch (e: any) {
    if (e.errors[0].code === 112) {
      yield call(refreshAccessToken)
      yield put(actions.getPostPropertiesRequest(data))
    } else {
      yield put(actions.getPostPropertiesError(e))
    }
  }
}

function* deletePostProperty({ data }: IAction) {
  try {
    yield call(api.deletePostProperty, data.workspaceId, data.id)

    yield put(actions.deletePostPropertySuccess(data.id))
  } catch (e: any) {
    if (e.errors[0].code === 112) {
      yield call(refreshAccessToken)
      yield put(actions.deletePostPropertyRequest(data))
    } else {
      yield put(actions.deletePostPropertyError(e))
    }
  }
}

function* savePostPropertiesChanges({ data }: IAction) {
  try {
    const workspaceId: number = yield select(getWorkspaceActiveId)
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].status === EPropertyStatus.updated) {
        yield call(api.updatePostProperty, workspaceId, data[i])
      } else if (data[i].status === EPropertyStatus.new) {
        const response = yield call(api.createPostProperty, workspaceId, data[i])
        yield put(actions.submitPostPropertySuccess(response.data.property))
      }
      yield i
    }
    yield put(actions.savePostPropertyChangesSuccess())
  } catch (e: any) {
    if (e.errors && e.errors[0].code === 112) {
      yield call(refreshAccessToken)
      yield put(actions.savePostPropertyChangesRequest(data))
    } else {
      yield put(actions.savePostPropertyChangesError(e))
    }
  }
}

function* saveUserPropertiesChanges({ data }: IAction) {
  try {
    const workspaceId: number = yield select(getWorkspaceActiveId)
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].status === EPropertyStatus.updated) {
        yield call(api.updateUserProperty, workspaceId, data[i])
      } else if (data[i].status === EPropertyStatus.new) {
        const response = yield call(api.createUserProperty, workspaceId, data[i])
        yield put(actions.submitUserPropertySuccess(response.data.property))
      }
      yield i
    }
    yield put(actions.saveUserPropertyChangesSuccess())
  } catch (e: any) {
    if (e.errors && e.errors[0].code === 112) {
      yield call(refreshAccessToken)
      yield put(actions.saveUserPropertyChangesRequest(data))
    } else {
      yield put(actions.saveUserPropertyChangesError(e))
    }
  }
}

function* getUserTypes({ data }: IAction) {
  try {
    const response = yield call(api.getUserTypes, data)
    yield put(actions.getUserTypesSuccess(response.data.customerTypes))
  } catch (e: any) {
    if (e.errors[0].code === 112) {
      yield call(refreshAccessToken)
      yield put(actions.getUserTypesRequest(data))
    } else {
      yield put(actions.getUserTypesError(e))
    }
  }
}

function* getPostTypes({ data }: IAction) {
  try {
    const response = yield call(api.getPostTypes, data)
    yield put(actions.getPostTypesSuccess(response.data.postTypes))
  } catch (e: any) {
    if (e.errors[0].code === 112) {
      yield call(refreshAccessToken)
      yield put(actions.getPostTypesRequest(data))
    } else {
      yield put(actions.getPostTypesError(e))
    }
  }
}

function* updatePostType({ data }: IAction) {
  try {
    const workspaceId: number = yield select(getWorkspaceActiveId)

    const response = yield call(api.updatePostType, workspaceId, data)

    yield put(actions.updatePostTypeSuccess(response.data.postTypes))
  } catch (e: any) {
    if (e.errors[0].code === 112) {
      yield call(refreshAccessToken)
      yield put(actions.updatePostTypeRequest(data))
    } else {
      yield put(actions.updatePostTypeError(e))
    }
  }
}

function* createPostType({ data }: IAction) {
  try {
    const workspaceId: number = yield select(getWorkspaceActiveId)

    const response = yield call(api.createPostType, workspaceId, data)

    yield put(actions.createPostTypeSuccess(response.data.postType))
  } catch (e: any) {
    if (e.errors[0].code === 112) {
      yield call(refreshAccessToken)
      yield put(actions.createPostTypeRequest(data))
    } else {
      yield put(actions.createPostTypeError(e))
    }
  }
}

function* deletePostType({ data }: IAction) {
  try {
    const workspaceId: number = yield select(getWorkspaceActiveId)

    yield call(api.deletePostType, workspaceId, data)
    yield put(actions.deletePostTypeSuccess(data))
  } catch (e: any) {
    if (e.errors[0].code === 112) {
      yield call(refreshAccessToken)
      yield put(actions.deletePostTypeRequest(data))
    } else {
      yield put(actions.deletePostTypeError(e))
    }
  }
}

function* deleteCustomerType({ data }: IAction) {
  try {
    const workspaceId: number = yield select(getWorkspaceActiveId)

    yield call(api.deleteCustomerType, workspaceId, data)
    yield put(actions.deleteCustomerTypeSuccess(data))
  } catch (e: any) {
    if (e.errors[0].code === 112) {
      yield call(refreshAccessToken)
      yield put(actions.deleteCustomerTypeRequest(data))
    } else {
      yield put(actions.deleteCustomerTypeError(e))
    }
  }
}

function* updateCustomerType({ data }: IAction) {
  try {
    const workspaceId: number = yield select(getWorkspaceActiveId)

    const response = yield call(api.updateCustomerType, workspaceId, data)

    yield put(actions.updateCustomerTypeSuccess(response.data.postType))
  } catch (e: any) {
    if (e.errors[0].code === 112) {
      yield call(refreshAccessToken)
      yield put(actions.updateCustomerTypeRequest(data))
    } else {
      yield put(actions.updateCustomerTypeError(e))
    }
  }
}

function* createCustomerType({ data }: IAction) {
  try {
    const workspaceId: number = yield select(getWorkspaceActiveId)

    const response = yield call(api.createCustomerType, workspaceId, data)

    yield put(actions.createCustomerTypeSuccess(response.data.customerType))
  } catch (e: any) {
    if (e.errors[0].code === 112) {
      yield call(refreshAccessToken)
      yield put(actions.createCustomerTypeRequest(data))
    } else {
      yield put(actions.createCustomerTypeError(e))
    }
  }
}

function* settingsSaga(): any {
  yield takeLatest("GET_USER_PROPERTIES_REQUEST", getUserProperties)
  yield takeLatest("GET_USER_TYPES_REQUEST", getUserTypes)
  yield takeLatest('DELETE_USER_PROPERTY_REQUEST', deleteUserProperty)
  yield takeLatest("GET_POST_TYPES_REQUEST", getPostTypes)
  yield takeLatest('SAVE_USER_PROPERTIES_CHANGES', saveUserPropertiesChanges)
  yield takeLatest("GET_POST_PROPERTIES_REQUEST", getPostProperties)
  yield takeLatest('DELETE_POST_PROPERTY_REQUEST', deletePostProperty)
  yield takeLatest('SAVE_POST_PROPERTIES_CHANGES', savePostPropertiesChanges)
  yield takeLatest('UPDATE_POST_TYPE_REQUEST', updatePostType)
  yield takeLatest('CREATE_POST_TYPE_REQUEST', createPostType)
  yield takeLatest('DELETE_POST_TYPE_REQUEST', deletePostType)
  yield takeLatest('UPDATE_CUSTOMER_TYPE_REQUEST', updateCustomerType)
  yield takeLatest('CREATE_CUSTOMER_TYPE_REQUEST', createCustomerType)
  yield takeLatest('DELETE_CUSTOMER_TYPE_REQUEST', deleteCustomerType)
}

export default settingsSaga