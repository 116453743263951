import { IFeed, IUpdateFeed } from 'types/feeds'
import axios from 'utils/axios'
import { API_FEED_BASE_URL } from '../config'

export const getFeeds = (workspaceId: number, pageToken?: string): Promise<any> => axios.get(`${API_FEED_BASE_URL}/pages/${workspaceId}/feeds${pageToken ? (
  `?pageToken=${pageToken}`
) : ('')}`)

export const getFeed = (workspaceId: number, feedId: number): Promise<any> => axios.get(`${API_FEED_BASE_URL}/pages/${workspaceId}/feeds/${feedId}`)

export const updateFeed = (workspaceId: number, feedId: number, body: IUpdateFeed): Promise<any> => axios.put(`${API_FEED_BASE_URL}/pages/${workspaceId}/feeds/${feedId}`, body)

export const removeFeed = (workspaceId: number, feedId: number): Promise<any> => axios.delete(`${API_FEED_BASE_URL}/pages/${workspaceId}/feeds/${feedId}`)

export const createFeed = (workspaceId: number, body: IFeed): Promise<any> => axios.post(`${API_FEED_BASE_URL}/pages/${workspaceId}/feeds`, body)
