import { IAction, IPostsState } from 'types/redux'
import * as actions from 'actions/postActions'

const initialState: IPostsState = {
  hasMore: false,
  pageToken: '',
  postsCount: 0,
  items: [],
  isLoading: false,
  createdPostId: null,
  error: null,
}

const posts = (state: IPostsState = initialState, action: IAction): Object => {
  switch (action.type) {
    case actions.GET_POSTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actions.GET_POSTS_SUCCESS:
      const {
        hasMore,
        pageToken,
        postsCount,
      } = action.data

      return {
        ...state,
        hasMore,
        pageToken,
        postsCount,
        isLoading: false,
        items: [...state?.items || [], ...action.data.posts]
      }
    case actions.GET_POSTS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    case actions.CREATE_POST_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case actions.CREATE_POST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: [...state.items, action.data.post],
        createdPostId: action.data.post.id
      }
    case actions.CREATE_POST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    case actions.UPDATE_POST_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case actions.UPDATE_POST_STATUS_SUCCESS:
      const postStatusItems = state.items.map(item => item.id === action.data.id ? action.data : item)
      return {
        ...state,
        isLoading: false,
        items: postStatusItems
      }
    case actions.UPDATE_POST_STATUS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    case actions.UPDATE_POST_REQUEST:
      const { val, id, postId } = action.data
      const newData = state.items?.map((el, i) => {
        const currPostIndex = state.items?.findIndex(item => item.id === postId)

        if (i === currPostIndex) {
          const currPropIndex = el.properties?.findIndex(item => item.id === id)

          if (currPropIndex !== -1) {
            // eslint-disable-next-line no-param-reassign
            el.properties[currPropIndex].value = val
          }

          return el
        }

        return el
      })

      return {
        ...state,
        items: newData,
        isLoading: true,
        error: null
      }
    case actions.UPDATE_POST_SUCCESS:
      const postItems = state.items.map(item => item.id === action.data.post.id ? action.data.post : item)
      return {
        ...state,
        isLoading: false,
        items: postItems
      }
    case actions.UPDATE_POST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    case actions.DELETE_POST_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case actions.DELETE_POST_SUCCESS:
      const deletePostItems = state.items.filter((x) => x.id !== action.data)
      return {
        ...state,
        isLoading: false,
        items: deletePostItems
      }
    case actions.DELETE_POST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    case actions.GET_POST_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case actions.GET_POST_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case actions.GET_POST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default posts