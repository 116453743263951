import { put, takeLatest, call, fork } from "redux-saga/effects";
import jwtDecode from 'jwt-decode'
import * as actions from 'actions/authActions'
import { resetGlobalLoader } from 'actions/globalActions'
import * as api from 'api/authApi'
import { IAction } from 'types/redux'
import axios from 'axios'
import { history } from 'App'

function* registerUser({ data }: IAction) {
  try {
    yield call(api.registerUser, data)
    yield put(actions.registerUserSuccess())
  } catch (e: any) {
    yield put(actions.registerUserError(e.errors))
  }
}

function* loginUser({ data }: IAction) {
  try {
    const response = yield call(api.loginUser, data)
    const { accessToken, refreshToken, user } = response.data

    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('refreshToken', refreshToken)

    yield put(resetGlobalLoader(true))
    yield put(actions.loginUserSuccess(user))
  } catch (e: any) {
    yield put(actions.loginUserError(e.errors))
  }
}

function* logoutUser() {
  try {
    yield localStorage.clear()
    yield put(actions.resetAuthStore())
    yield put(resetGlobalLoader(false))
    history.push('/')
  } catch (e: any) {
    // eslint-disable-next-line
    console.log(e)
  }
}

function* getAuthMe() {
  try {
    const response = yield call(api.getAuthMe)
    const { user } = response.data

    yield put(actions.getAuthMeSuccess(user))
  } catch (e: any) {
    if (e.errors[0].code === 112 || e.errors[0].code === 111) {
      // accessToken expired
      yield fork(refreshAccessToken);
    } else {
      yield put(actions.getAuthMeError(e.errors))
      yield put(resetGlobalLoader(false))
    }
  }
}

export function* refreshAccessToken(): any {
  try {
    const token = localStorage.getItem("refreshToken")

    axios.defaults.headers.common.Authorization = `Bearer ${token}`

    const response = yield call(api.refreshAccessToken)
    const { accessToken, refreshToken } = response.data

    const user: any = jwtDecode(accessToken)

    yield localStorage.setItem("accessToken", accessToken)
    yield localStorage.setItem("refreshToken", refreshToken)

    yield put(actions.getAuthMeSuccess(user.accessToken))
  } catch (e: any) {
    // refreshToken expired. Logout user
    yield fork(logoutUser);
  }
}

function* authSaga(): any {
  yield takeLatest("REGISTER_USER_REQUEST", registerUser)
  yield takeLatest("LOGIN_USER_REQUEST", loginUser)
  yield takeLatest("GET_AUTH_ME_REQUEST", getAuthMe)
  yield takeLatest("LOGOUT_USER_REQUEST", logoutUser)
}

export default authSaga;
