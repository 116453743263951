import React from 'react'
import { makeStyles, SvgIconProps } from '@material-ui/core'

const useStyle = makeStyles({
  links: {
    padding: '8px',
    '& a': {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: '#FFF',
      opacity: '0.54',
      marginBottom: '16px',
      '&:hover': {
        opacity: 1
      }
    }
  },
})

export interface ILink {
  ref: string,
  icon: (props: SvgIconProps) => JSX.Element,
  caption: string,
}

type ActionLinksProps = {
  links?: Array<ILink>
}

const ActionLinks = ({ links }: ActionLinksProps): JSX.Element => {
  const classes = useStyle()

  return (
    <div className={classes.links}>
      {links?.map(link => {
        const Icon = link.icon;

        return (
          <a href={link.ref} key={link.caption}>
            <Icon style={{ width: '20px', marginRight: '8px' }} />
            <span>{link.caption}</span>
          </a>
        )
      })}
    </div>
  )
}

export default ActionLinks