import { IAction, IError } from 'types/redux'
import { ICreateCustomerData, ICustomer, ICustomerResponse, ISearchCustomerResponse } from 'types/users'

export const GET_CUSTOMERS_REQUEST = "GET_CUSTOMERS_REQUEST"
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS"
export const GET_CUSTOMERS_ERROR = "GET_CUSTOMERS_ERROR"

export const SEARCH_CUSTOMERS_REQUEST = "SEARCH_CUSTOMERS_REQUEST"
export const SEARCH_CUSTOMERS_SUCCESS = "SEARCH_CUSTOMERS_SUCCESS"
export const SEARCH_CUSTOMERS_ERROR = "SEARCH_CUSTOMERS_ERROR"

export const DELETE_CUSTOMERS_REQUEST = "DELETE_CUSTOMERS_REQUEST"
export const DELETE_CUSTOMERS_SUCCESS = "DELETE_CUSTOMERS_SUCCESS"
export const DELETE_CUSTOMERS_ERROR = "DELETE_CUSTOMERS_ERROR"

export const CREATE_CUSTOMER_REQUEST = "CREATE_CUSTOMER_REQUEST"
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS"
export const CREATE_CUSTOMER_ERROR = "CREATE_CUSTOMER_ERROR"

export const SET_SEARCH_STRING = "SET_SEARCH_STRING"

export const RESET_SEARCH_DATA = "RESET_SEARCH_DATA"

export const RESET_CREATED_USER = "RESET_CREATED_USER"

export const EDIT_INTERNAL_USER = 'EDIT_INTERNAL_USER'

export const getUsers = (data: number): IAction => ({
  type: GET_CUSTOMERS_REQUEST,
  data
})

export const getUsersSuccess = (data: ICustomerResponse): IAction => ({
  type: GET_CUSTOMERS_SUCCESS,
  data
})

export const getUsersError = (error: IError): IAction => ({
  type: GET_CUSTOMERS_ERROR,
  error
})

export const searchUsersRequest = (data: string): IAction => ({
  type: SEARCH_CUSTOMERS_REQUEST,
  data
})

export const searchUsersSuccess = (data: ISearchCustomerResponse): IAction => ({
  type: SEARCH_CUSTOMERS_SUCCESS,
  data
})

export const searchUsersError = (error: IError): IAction => ({
  type: SEARCH_CUSTOMERS_REQUEST,
  error
})

export const setSearchString = (data: string): IAction => ({
  type: SET_SEARCH_STRING,
  data
})

export const resetSearchData = (): IAction => ({
  type: RESET_SEARCH_DATA
})

export const deleteCustomerRequest = (data: number): IAction => ({
  type: DELETE_CUSTOMERS_REQUEST,
  data
})

export const deleteCustomerSuccess = (data: number): IAction => ({
  type: DELETE_CUSTOMERS_SUCCESS,
  data
})

export const deleteCustomerError = (error: IError): IAction => ({
  type: DELETE_CUSTOMERS_ERROR,
  error
})

export const editInternalUser = (data: object): IAction => ({
  type: EDIT_INTERNAL_USER,
  data
})

export const createCustomerRequest = (data: ICreateCustomerData): IAction => ({
  type: CREATE_CUSTOMER_REQUEST,
  data
})

export const createCustomerSuccess = (data: ICustomer): IAction => ({
  type: CREATE_CUSTOMER_SUCCESS,
  data
})

export const createCustomerError = (error: IError): IAction => ({
  type: CREATE_CUSTOMER_ERROR,
  error
})

export const resetCreatedUser = (): IAction => ({
  type: RESET_CREATED_USER
})