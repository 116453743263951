import { IAction, IError } from 'types/redux'
import { IValues, IAuthResponse } from 'types/authView'

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR'

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR'

export const GET_AUTH_ME_REQUEST = 'GET_AUTH_ME_REQUEST'
export const GET_AUTH_ME_SUCCESS = 'GET_AUTH_ME_SUCCESS'
export const GET_AUTH_ME_ERROR = 'GET_AUTH_ME_ERROR'

export const RESET_AUTH_STORE = 'RESET_AUTH_STORE'

export const LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST'

export const registerUserRequest = (data: IValues): IAction => ({
  type: REGISTER_USER_REQUEST,
  data
})

export const registerUserSuccess = (): IAction => ({
  type: REGISTER_USER_SUCCESS
})

export const registerUserError = (error: IError): IAction => ({
  type: REGISTER_USER_ERROR,
  error
})

export const loginUserRequest = (data: IValues): IAction => ({
  type: LOGIN_USER_REQUEST,
  data
})

export const loginUserSuccess = (data: IAuthResponse): IAction => ({
  type: LOGIN_USER_SUCCESS,
  data
})

export const loginUserError = (error: IError): IAction => ({
  type: LOGIN_USER_ERROR,
  error
})

export const getAuthMeRequest = (): IAction => ({
  type: GET_AUTH_ME_REQUEST
})

export const getAuthMeSuccess = (data: IAuthResponse): IAction => ({
  type: GET_AUTH_ME_SUCCESS,
  data
})

export const getAuthMeError = (error: IError | any): IAction => ({
  type: GET_AUTH_ME_ERROR,
  error
})

export const resetAuthStore = (): IAction => ({
  type: RESET_AUTH_STORE
})

export const logoutRequest = (): IAction => ({
  type: LOGOUT_USER_REQUEST
})