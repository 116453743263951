import { IAction, IResponsiveState } from "types/redux";
import * as actions from 'actions/responsiveActions'

const initialState = {
  menuOpen: false,
  sidebarOpen: false,
}

const responsive = (state: IResponsiveState = initialState, action: IAction): IResponsiveState => {
  switch(action.type){
    case actions.SET_MENU_OPEN:
      return {
        ...state,
        menuOpen: action.data
      }
    case actions.SET_SIDEBAR_OPEN:
      return {
        ...state,
        sidebarOpen: action.data
      }
    default:
      return state
  }
}

export default responsive