import { IPageProperty, IPageType } from 'types/settings'
import axios from 'utils/axios'
import { API_USERS_BASE_URL, API_POSTS_BASE_URL } from '../config'

export const getUserProperties = (workspaceId: number): Promise<any> => axios.get(`${API_USERS_BASE_URL}/pages/${workspaceId}/properties`)

export const createUserProperty = (
  workspaceId: number,
  { name, type, required, customerType, sort }: IPageProperty
): Promise<any> =>
  axios.post(
    `${API_USERS_BASE_URL}/pages/${workspaceId}/properties`,
    {
      name, type, required, customerType, sort
    }
  )

export const updateUserProperty = (
  workspaceId: number,
  { id, name, type, required, customerType, sort }: IPageProperty
): Promise<any> =>
  axios.put(
    `${API_USERS_BASE_URL}/pages/${workspaceId}/properties/${id}`,
    { name, type, required, customerType, sort }
  )

export const deleteUserProperty = (workspaceId: number, id: number): Promise<any> => axios.delete(`${API_USERS_BASE_URL}/pages/${workspaceId}/properties/${id}`)

export const getPostProperties = (workspaceId: number): Promise<any> => axios.get(`${API_POSTS_BASE_URL}/pages/${workspaceId}/properties`)

export const createPostProperty = (
  workspaceId: number,
  { name, type, required, postType, sort }: IPageProperty
): Promise<any> =>
  axios.post(
    `${API_POSTS_BASE_URL}/pages/${workspaceId}/properties`,
    {
      name, type, required, postType, sort
    }
  )

export const updatePostProperty = (
  workspaceId: number,
  { id, name, type, required, postType, sort }: IPageProperty
): Promise<any> =>
  axios.put(
    `${API_POSTS_BASE_URL}/pages/${workspaceId}/properties/${id}`,
    { name, type, required, postType, sort }
  )

export const deletePostProperty = (workspaceId: number, id: number): Promise<any> => axios.delete(`${API_POSTS_BASE_URL}/pages/${workspaceId}/properties/${id}`)

export const getUserTypes = (workspaceId: number): Promise<any> => axios.get(`${API_USERS_BASE_URL}/pages/${workspaceId}/customerTypes`)

export const getPostTypes = (workspaceId: number): Promise<any> => axios.get(`${API_POSTS_BASE_URL}/pages/${workspaceId}/postTypes`)

export const createPostType = (workspaceId: number, data: IPageType): Promise<any> => axios.post(`${API_POSTS_BASE_URL}/pages/${workspaceId}/postTypes`, data)

export const updatePostType = (workspaceId: number, data: IPageType): Promise<any> => axios.put(`${API_POSTS_BASE_URL}/pages/${workspaceId}/postTypes/${data.id}`, data)

export const deletePostType = (workspaceId: number, data: number): Promise<any> => axios.delete(`${API_POSTS_BASE_URL}/pages/${workspaceId}/postTypes/${data}`)

export const deleteCustomerType = (workspaceId: number, data: number): Promise<any> => axios.delete(`${API_USERS_BASE_URL}/pages/${workspaceId}/customerTypes/${data}`)

export const createCustomerType = (workspaceId: number, data: IPageType): Promise<any> => axios.post(`${API_USERS_BASE_URL}/pages/${workspaceId}/customerTypes`, data)

export const updateCustomerType = (workspaceId: number, data: IPageType): Promise<any> => axios.put(`${API_USERS_BASE_URL}/pages/${workspaceId}/customerTypes/${data.id}`, data)
