import { call, put, select, takeLatest, debounce } from 'redux-saga/effects'
import * as api from 'api/postsApi'
import * as actions from 'actions/postActions'
import { IAction } from 'types/redux'
import { getWorkspaceActiveId } from 'selectors'
import { refreshAccessToken } from './authSaga'

function* getPosts({ data, token }: IAction) {
  try {
    const response = yield call(api.getPosts, data, token)

    yield put(actions.getPostsSuccess(response.data))
  } catch (e: any) {
    if (e.errors[0].code === 112) {
      yield call(refreshAccessToken)
      yield put(actions.getPostsRequest(data))
    } else {
      yield put(actions.getPostsError(e.errors))
    }
  }
}

function* getPost({ data }: IAction) {
  try {
    const response = yield call(api.getPost, data)

    yield put(actions.getPostSuccess(response.data))
  } catch (e: any) {
    if (e.errors[0].code === 112) {
      yield call(refreshAccessToken)
      yield put(actions.getPostRequest(data))
    } else {
      yield put(actions.getPostError(e.errors))
    }
  }
}

function* createPost({ data }: IAction) {
  try {
    const workspaceId: number = yield select(getWorkspaceActiveId)
    const response = yield call(api.createPost, workspaceId, data)

    yield put(actions.createPostSuccess(response.data))
  } catch (e: any) {
    if (e.errors[0].code === 112) {
      yield call(refreshAccessToken)

      yield put(actions.createPostRequest(data))
    } else {
      yield put(actions.createPostError(e.errors))
    }
  }
}

function* setPostStatus({ data }: IAction) {
  try {
    const workspaceId: number = yield select(getWorkspaceActiveId)
    const postId = data.id

    const ids = {
      workspaceId,
      postId
    }
    const response = yield call(api.setPostStatus, ids, data.status)

    yield put(actions.updatePostStatusSuccess(response.data))
  } catch (e: any) {
    if (e.errors[0].code === 112) {
      yield call(refreshAccessToken)

      yield put(actions.updatePostStatusRequest(data))
    } else {
      yield put(actions.updatePostStatusError(e.errors))
    }
  }
}

function* deletePost({ data }: IAction) {
  try {
    const workspaceId = yield select(getWorkspaceActiveId)
    yield call(api.deletePost, workspaceId, data)

    yield put(actions.deletePostSuccess(data))
  } catch (e: any) {
    if (e.errors[0].code === 112) {
      yield call(refreshAccessToken)

      yield put(actions.deletePostRequest(data))
    } else {
      yield put(actions.deletePostError(e.errors))
    }
  }
}

function* updatePost({ data }: IAction) {
  try {
    const workspaceId = yield select(getWorkspaceActiveId)
    const { id, postId, val, restProp } = data
    const reqObj = {
      properties: [{
        id,
        value: val
      }, ...restProp]
    }
    yield call(api.updatePost, workspaceId, postId, reqObj)
  } catch (e: any) {
    if (e.errors[0].code === 112) {
      yield call(refreshAccessToken)

      yield put(actions.updatePostRequest(data))
    } else {
      yield put(actions.updatePostError(e.errors))
    }
  }
}

function* postsSaga(): any {
  yield takeLatest("GET_POSTS_REQUEST", getPosts)
  yield takeLatest("GET_POST_REQUEST", getPost)
  yield takeLatest("CREATE_POST_REQUEST", createPost)
  yield takeLatest("DELETE_POST_REQUEST", deletePost)
  yield takeLatest("UPDATE_POST_STATUS_REQUEST", setPostStatus)
  yield debounce(300, "UPDATE_POST_REQUEST", updatePost)

}

export default postsSaga