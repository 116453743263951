import { IState, IUserState } from 'types/redux'
import { ICustomer } from 'types/users'

export const getUserState = ({ users }: IState): IUserState => users

export const getAllUsersList = ({ users }: IState): ICustomer[] | null => users.data

export const getUserSearch = ({ users }: IState): string => users.searchString

export const getUserSearchData = ({ users }: IState): Array<ICustomer> => users.searchData || []

export const getCreatedUserId = ({ users }: IState): number | null => users.createdUserId