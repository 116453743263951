import { IAction } from 'types/redux'

export const RESET_GLOBAL_LOADER = 'RESET_GLOBAL_LOADER'
export const SHOW_TOAST = 'SHOW_TOAST'
export const HIDE_TOAST = 'HIDE_TOAST'
export const RESET_ALL_DATA = 'RESET_ALL_DATA'

export const resetGlobalLoader = (data: boolean): IAction => ({
  type: RESET_GLOBAL_LOADER,
  data
})

export const showToast = (): IAction => ({
  type: SHOW_TOAST
})

export const hideToast = (): IAction => ({
  type: HIDE_TOAST
})

export const resetAllData = (): IAction => ({
  type: RESET_ALL_DATA
})