import * as api from 'api/feedsApi'
import * as actions from 'actions/feedsActions'
import { showToast } from 'actions/globalActions'
import { takeLatest, call, put, select } from "redux-saga/effects"
import { IAction } from 'types/redux'
import { getWorkspaceActiveId } from 'selectors'
import { refreshAccessToken } from './authSaga'

function* getFeeds({ data }: IAction) {
  try {
    const response = yield call(api.getFeeds, data.workspaceId, data.pageToken)

    yield put(actions.getFeedsSuccess(response.data))
  } catch (e: any) {
    if (e.errors[0].code === 112) {
      yield call(refreshAccessToken)

      yield put(actions.getFeedsRequest(data))
    } else {
      yield put(actions.getFeedsError(e.errors))
    }
  }
}

function* getFeed({ data }: IAction) {
  try {
    const response = yield call(api.getFeed, data.workspaceId, data.feedId)

    yield put(actions.getFeedSuccess(response.data))
  } catch (e: any) {
    if (e.errors[0].code === 112) {
      yield call(refreshAccessToken)

      yield put(actions.getFeedRequest(data))
    } else {
      yield put(actions.getFeedError(e.errors))
    }
  }
}

function* createFeed({ data }: IAction) {
  try {
    const response = yield call(api.createFeed, data.workspaceId, data.body)

    yield put(actions.createFeedSuccess(response.data.feed))
    data.closeScreen()
    yield put(showToast())
  } catch (e: any) {
    if (e.errors[0].code === 112) {
      yield call(refreshAccessToken)

      yield put(actions.createFeedRequest(data))
    } else {
      yield put(actions.createFeedError(e.errors))
    }
  }
}

function* deleteFeed({ data }: IAction) {
  try {
    const workspaceId = yield select(getWorkspaceActiveId)

    yield call(api.removeFeed, workspaceId, data)

    yield put(actions.removeFeedSuccess(data))
  } catch (e: any) {
    if (e.errors[0].code === 112) {
      yield call(refreshAccessToken)

      yield put(actions.removeFeedRequest(data))
    } else {
      yield put(actions.removeFeedError(e.errors))
    }
  }
}

function* updateFeed({ data }: IAction) {
  try {
    const response = yield call(api.updateFeed, data.workspaceId, data.feedId, data.body)

    yield put(actions.updateFeedSuccess(response.data))
  } catch (e: any) {
    if (e.errors[0].code === 112) {
      yield call(refreshAccessToken)

      yield put(actions.updateFeedRequest(data))
    } else {
      yield put(actions.updateFeedError(e.errors))
    }
  }
}

function* feedsSaga(): any {
  yield takeLatest("GET_FEEDS_REQUEST", getFeeds)
  yield takeLatest("GET_FEED_REQUEST", getFeed)
  yield takeLatest("CREATE_FEED_REQUEST", createFeed)
  yield takeLatest("REMOVE_FEED_REQUEST", deleteFeed)
  yield takeLatest("UPDATE_FEED_REQUEST", updateFeed)
}

export default feedsSaga