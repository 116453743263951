import { IAction } from "types/redux"

export const SET_MENU_OPEN = "SET_MENU_OPEN"
export const SET_SIDEBAR_OPEN = "SET_SIDEBAR_OPEN"

export const setMenuOpen = (data: boolean): IAction => ({
  type: SET_MENU_OPEN,
  data
})

export const setSidebarOpen = (data: boolean): IAction => ({
  type: SET_SIDEBAR_OPEN,
  data
})